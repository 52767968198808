import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles.m.css";
import { Icon } from "../../atoms/icon";
import { DeepBI, WebEngage } from "../../../integrations";
import { get } from "lodash";
import { btoa } from "isomorphic-base64";
import ResponsiveImageWithFallback from "../../atoms/responsive-image-with-fallback";

import { generateImageSources } from "../../utils/utils";
import CompanionAd from "../../atoms/companian-ad-vast";
import MobileLogin from "../../rows/header/user-login/login-mobile";

class AudioMagazinePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
      currentTime: 0,
      duration: 0,
      volume: 1,
      shouldRender: false,
      showSpeedOptions: false,
      selectedSpeed: 1,
      currentStoryId: get(this.props, ["story"]),
      isAdPlaying: false,
      userType: "F",
      userStatus: true,
      showLoginModal: false
    };

    this.audioRef = React.createRef();
    this.adContainerRef = React.createRef();
  }

  componentDidMount() {
    global.userPromise.catch(() => ({})).then(user => {
      user && this.setState({ userType: user.userType, userStatus: user.loggedOut });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.ClosePlayer) {
      if (prevProps.enablePlayer !== this.props.enablePlayer) {
        const magazineID = get(this.props, ["CurrentCollection", "id"]);
        const storyId = get(this.props, ["CurrentStory", "id"]);
        const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/hls.js@latest";
        script.async = true;
        script.onload = () => {
          this.initializePlayer(magazineID, storyId, storyType);
        };
        document.body.appendChild(script);
      }
      if (prevProps.PlayAudio !== this.props.PlayAudio) {
        const magazine = get(this.props, ["CurrentCollection"]);
        const story = get(this.props, ["CurrentStory"]);
        this.props.PlayAudio ? this.handlePlay(magazine, story) : this.handlePause(magazine, story);
      }
    }

    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    const prevStoryId = get(prevProps, ["CurrentStory", "id"]);
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    if (prevStoryId !== undefined && currentStoryId !== undefined && currentStoryId !== prevStoryId) {
      // if (this.props.PlayAudio && this.props.enablePlayer) {
      const magazineID = get(this.props, ["CurrentCollection", "id"]);
      this.initializePlayer(magazineID, currentStoryId, storyType);
      this.handlePlay(get(this.props, ["CurrentCollection"]), get(this.props, ["CurrentStory"]));
      // }
    }
  }

  initializePlayer = (magazineID, storyId, storyType) => {
    const encodedMagazineID = btoa(magazineID);
    let audioUrl;

    if (storyType && storyType !== undefined && storyType.includes("Audiobook")) {
      audioUrl = `https://audiomag.vikatan.com/audio-book/${encodedMagazineID}/${storyId}/${storyId}.m3u8`;
    } else {
      audioUrl = `https://audiomag.vikatan.com/${encodedMagazineID}/${storyId}/${storyId}.m3u8`;
    }
    if (window.Hls.isSupported()) {
      if (this.hls) {
        this.hls.destroy();
      }
      this.hls = new window.Hls();
      this.hls.loadSource(audioUrl);
      this.hls.attachMedia(this.audioRef.current);
      this.hls.on(window.Hls.Events.MANIFEST_PARSED, () => {
        this.audioRef.current.playbackRate = this.state.selectedSpeed; // Set the playback speed
      });
    } else if (this.audioRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      this.audioRef.current.src = audioUrl;
      this.audioRef.current.load();
      this.audioRef.current.playbackRate = this.state.selectedSpeed; // Set the playback speed
    }
  };

  componentWillUnmount() {
    if (this.hls) {
      this.hls.destroy();
    }
  }

  handlePlay = (magazine, story) => {
    const { userType, userStatus } = this.state;

    if (userStatus) {
      this.setState({ showLoginModal: true });
      DeepBI.PingLoginevents("Login_widget_view", "", "", "", "", "vikatan_play_player");
      return;
    }

    if (userType === "P" || userType === "T" || userType === "R") {
      const adMessage = document.getElementById("ad-message");
      adMessage.style.display = "none";

      this.handlePlayAfterAds(magazine, story); // Custom function to directly play the audio
      return;
    }

    const audioElement = this.audioRef.current;

    // Initialize ad variables
    const adContainer = this.adContainerRef.current;
    const playButton = document.getElementById("play-button");
    const progressBar = document.getElementById("ad-progress-bar"); // Custom progress bar
    const timeLeftLabel = document.getElementById("ad-time-left");
    const adMessage = document.getElementById("ad-message");

    const google = window.google;

    // Hide ad message and play button initially
    adMessage.style.display = "none";
    playButton.style.display = "none";

    // Ensure a new adDisplayContainer is created each time
    if (this.adDisplayContainer) {
      this.adDisplayContainer.destroy(); // Clean up previous adDisplayContainer if it exists
      this.adDisplayContainer = null;
    }

    // IMA SDK for VAST ads setup

    const adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, audioElement);
    adDisplayContainer.initialize();

    const adsLoader = new google.ima.AdsLoader(adDisplayContainer);

    // DeepBI.PingAudioplayer(story, "Ad_request", magazine);

    adsLoader.addEventListener(
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      adsManagerLoadedEvent => {
        const adsManager = adsManagerLoadedEvent.getAdsManager(audioElement);

        adsManager.addEventListener(google.ima.AdEvent.Type.IMPRESSION, () => {
          adMessage.style.display = "block"; // Show ad message
          DeepBI.PingAudioplayer(story, "Ad_impression", magazine);
        });

        // Send Ad_started when the ad starts playing
        adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
          DeepBI.PingAudioplayer(story, "Ad_started", magazine);
        });

        // Ad progress tracking
        adsManager.addEventListener(google.ima.AdEvent.Type.FIRST_QUARTILE, () => {
          DeepBI.PingAudioplayer(story, "Ad_first_quartile", magazine);
        });

        adsManager.addEventListener(google.ima.AdEvent.Type.MIDPOINT, () => {
          DeepBI.PingAudioplayer(magazine, "Ad_midpoint", magazine);
        });

        adsManager.addEventListener(google.ima.AdEvent.Type.THIRD_QUARTILE, () => {
          DeepBI.PingAudioplayer(story, "Ad_third_quartile", magazine);
        });

        // Send Ad_complete when the ad finishes playing
        adsManager.addEventListener(google.ima.AdEvent.Type.COMPLETE, () => {
          DeepBI.PingAudioplayer(story, "Ad_complete", magazine);
        });

        // Send Ad_clicked event if the ad is clicked
        adsManager.addEventListener(google.ima.AdEvent.Type.CLICK, () => {
          DeepBI.PingAudioplayer(story, "Ad_clicked", magazine);
        });

        // Listen for when the ad starts to play
        adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, () => {
          // Get the total ad duration
          const totalAdDuration = adsManager.getRemainingTime();

          // Set up a progress interval to update the progress bar and time left
          const progressInterval = setInterval(() => {
            const remainingTime = adsManager.getRemainingTime();

            const elapsedTime = totalAdDuration - remainingTime;

            // Calculate the hours, minutes, and seconds for the remaining time
            //   const hours = Math.floor(remainingTime / 3600);
            const minutes = Math.floor((remainingTime % 3600) / 60);
            const seconds = Math.floor(remainingTime % 60);

            const formattedTime = `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

            // Update the progress bar (example assuming 100% is fully loaded)
            const progressPercentage = ((elapsedTime / totalAdDuration) * 100).toFixed(2);
            progressBar.style.width = `${progressPercentage}%`;

            // Update the time left label
            timeLeftLabel.textContent = `Ad: (${formattedTime})`;

            // Clear interval when ad is finished
            if (remainingTime <= 0) {
              clearInterval(progressInterval);
            }
          }, 1000); // Update every second
        });

        // adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, () => this.onAdError(magazine, story));
        // adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, this.onContentPauseRequested);
        //  adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, this.onContentResumeRequested);

        // Detect page visibility and pause/resume ad accordingly
        document.addEventListener("visibilitychange", () => {
          if (document.hidden) {
            // Pause the ad if the page becomes hidden (user leaves page)
            if (adsManager) {
              adsManager.pause();
            }
          } else {
            // Resume the ad if the page becomes visible again (user returns to page)
            if (adsManager) {
              adsManager.resume();
            }
          }
        });

        adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () =>
          this.onAllAdsCompleted(magazine, story)
        );

        try {
          adsManager.init(1, 1, google.ima.ViewMode.NORMAL);
          adsManager.start();
        } catch (adError) {
          console.error("AdsManager Error:", adError);
          this.handlePlayAfterAds(magazine, story); // Handle playback after ads if an error occurs
        }
      },
      false
    );

    adsLoader.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR,
      adErrorEvent => this.onAdError(adErrorEvent, magazine, story),
      false
    );

    const adsRequest = new google.ima.AdsRequest();
    adsRequest.adTagUrl =
      "https://pubads.g.doubleclick.net/gampad/ads?iu=/3849069/Web&description_url=[placeholder]&tfcd=0&npa=0&ad_type=audio&sz=1x1&ciu_szs=300x250&min_ad_duration=10000&max_ad_duration=60000&gdfp_req=1&unviewed_position_start=1&correlator=[placeholder]&url=https%3A%2F%2Fwww.vikatan.com%2Fwelcome-offer-29%3F&vpos=preroll&output=vast&env=vp&vpmute=0&vpa=auto";

    // adDisplayContainer.initialize();
    adsLoader.requestAds(adsRequest);
  };

  onAdError = (adErrorEvent, magazine, story) => {
    console.error("Ad Error:", adErrorEvent.getError().getMessage());

    const adMessage = document.getElementById("ad-message");
    const playButton = document.getElementById("play-button");

    adMessage.style.display = "none";
    playButton.style.display = "grid";

    this.handlePlayAfterAds(magazine, story);
  };

  onAllAdsCompleted = (magazine, story) => {
    const adMessage = document.getElementById("ad-message");
    const playButton = document.getElementById("play-button");

    adMessage.style.display = "none";
    playButton.style.display = "grid";

    this.setState({ isAdPlaying: true });

    this.handlePlayAfterAds(magazine, story);
  };

  handlePlayAfterAds = (magazine, story) => {
    const storyType = get(story, ["metadata", "story-attributes", "contenttype"]);

    const audioElement = this.audioRef.current;

    if (audioElement) {
      const playPromise = audioElement.play();
      this.props.setAudioplaying(true);

      // Determine the event name based on story type
      const playEventName =
        storyType && storyType.includes("Audiobook")
          ? audioElement.currentTime > 0
            ? "audiobookresumed"
            : "audiobookplayed"
          : audioElement.currentTime > 0
            ? "audiomagazineresumed"
            : "audiomagazineplayed";
      this.setState({ playing: true, currentTime: audioElement.currentTime });
      //  let currentTime = audioElement.currentTime;
      WebEngage.Webplayer(story, playEventName, magazine);
      DeepBI.PingAudioplayer(story, playEventName, magazine, this.formatSecondsTime(this.audioRef.current.currentTime));
      if (playPromise !== undefined) {
        // Handle the promise if it exists
        playPromise
          .then(() => {
            // Successfully started playback

            // let currentTime = audioElement.currentTime;

            WebEngage.Webplayer(story, playEventName, magazine);
          })
          .catch(error => {
            // Handle playback error
            console.error("Playback error:", error);

            // Attempt to pause, reset, and retry playback
            audioElement.pause();
            audioElement.currentTime = 0;
            audioElement.play().catch(retryError => {
              console.error("Retry playback error:", retryError);
            });
            this.setState({ playing: false });
            this.props.setAudioplaying(false);
          });
      } else {
        // Directly play if no promise is returned
        this.props.setAudioplaying(true);
        this.setState({ playing: true, currentTime: audioElement.currentTime });
        //   let currentTime = audioElement.currentTime;
        WebEngage.Webplayer(story, playEventName, magazine);
      }
    }
  };

  handlePause = (magazine, story) => {
    const storyType = get(story, ["metadata", "story-attributes", "contenttype"]);

    // Determine the event name based on story type
    const pauseEventName = storyType && storyType.includes("Audiobook") ? "audiobookpaused" : "audiomagazinepaused";
    this.audioRef.current.pause();
    this.props.setAudioplaying(false);
    this.setState({ playing: false, currentTime: this.audioRef.current.currentTime });
    DeepBI.PingAudioplayer(story, pauseEventName, magazine, this.formatSecondsTime(this.audioRef.current.currentTime));
    WebEngage.Webplayer(story, pauseEventName, magazine);
  };

  handleTimeUpdate = () => {
    this.setState({
      currentTime: this.audioRef.current.currentTime,
      duration: this.audioRef.current.duration
    });
  };

  setFinished = () => {
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    // Determine the event name based on story type
    const pauseEventName =
      storyType && storyType.includes("Audiobook") ? "audiobookcompleted" : "audiomagazinecompleted";
    this.setState({
      playing: false,
      currentTime: 0
    });
    this.props.setAudioplaying(this.props.PlayAudio);
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      pauseEventName,
      get(this.props, ["CurrentCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
    WebEngage.Webplayer(get(this.props, ["CurrentStory"]), pauseEventName, get(this.props, ["CurrentCollection"]));
    this.handleNext();
  };

  handleVolumeChange = e => {
    this.setState({ volume: e.target.value });
    this.audioRef.current.volume = e.target.value;
  };

  handleSeek = e => {
    this.audioRef.current.currentTime = e.target.value;
  };

  handleSpeedChange = speed => {
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    // Determine the event name based on story type
    const pauseEventName = storyType && storyType.includes("Audiobook") ? "audiobookspeed" : "audiomagazinespeed";
    const speeds = [1, 1.2, 1.5];
    const currentIndex = speeds.indexOf(speed);
    const newSpeed = currentIndex === speeds.length - 1 ? speeds[0] : speeds[currentIndex + 1];

    this.audioRef.current.playbackRate = newSpeed;
    this.setState({ selectedSpeed: newSpeed });

    // Logging events
    WebEngage.Webplayer(get(this.props, ["CurrentStory"]), pauseEventName, get(this.props, ["CurrentCollection"]));
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      pauseEventName,
      get(this.props, ["CurrentCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
  };

  formatTime = time => {
    if (isNaN(time)) {
      return "00:00";
    }
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return `${mm}:${ss}`;
  };

  formatSecondsTime = time => {
    const remainder = time % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = Math.floor(remainder % 60);

    const mm = minutes.toString().padStart(2, "0");
    const ss = seconds.toString().padStart(2, "0");

    return parseInt(mm) * 60 + parseInt(ss);
  };

  skip = time => {
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    if (time === "back") {
      // Determine the event name based on story type
      let pauseEventName =
        storyType && storyType.includes("Audiobook") ? "audiobookseekbackword" : "audiomagazineseekbackword";
      this.audioRef.current.currentTime = this.audioRef.current.currentTime - 15;
      WebEngage.Webplayer(get(this.props, ["CurrentStory"]), pauseEventName, get(this.props, ["CurrentCollection"]));
      DeepBI.PingAudioplayer(
        get(this.props, ["CurrentStory"]),
        pauseEventName,
        get(this.props, ["CurrentCollection"]),
        this.formatSecondsTime(this.audioRef.current.currentTime)
      );
    } else if (time === "fwd") {
      let pauseEventNameFwd =
        storyType && storyType.includes("Audiobook") ? "audiobookseekforward" : "audiomagazineseekforward";
      this.audioRef.current.currentTime = this.audioRef.current.currentTime + 15;
      WebEngage.Webplayer(get(this.props, ["CurrentStory"]), pauseEventNameFwd, get(this.props, ["CurrentCollection"]));

      DeepBI.PingAudioplayer(
        get(this.props, ["CurrentStory"]),
        pauseEventNameFwd,
        get(this.props, ["CurrentCollection"]),
        this.formatSecondsTime(this.audioRef.current.currentTime)
      );
    }
  };

  handleNext = () => {
    if (this.hls) {
      this.hls.destroy();
    }
    const stories = get(this.props, ["CurrentCollection", "items"] || []);

    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    // const magazineID = get(this.props, ["data", "issueCollection", "id"]);

    const currentStoryIndex = stories.findIndex(story => story.id === currentStoryId);

    if (currentStoryIndex === -1 || stories.length === 0) return; // No valid current story

    const storiesCount = stories.length;
    const nextIndex = (currentStoryIndex + 1) % storiesCount;
    const nextStory = stories[nextIndex];

    // Check if next story has audio available
    const audioAvailable = get(nextStory, ["story", "metadata", "story-attributes", "audiolength"], 0);
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    if ((storyType && storyType !== undefined && storyType.includes("Audiobook")) || audioAvailable > 0) {
      const pauseEventName = storyType && storyType.includes("Audiobook") ? "audiobooknext" : "audiomagazinenext";
      // console.log("audio-available");
      //   this.initializePlayer(magazineID, get(nextStory, ["story", "id"]));
      //  this.handlePlay(get(this.props, ["data", "issueCollection"]), get(nextStory, ["story"]));
      WebEngage.Webplayer(get(nextStory, ["story"]), pauseEventName, get(this.props, ["data", "issueCollection"]));
      DeepBI.PingAudioplayer(get(nextStory, ["story"]), pauseEventName, get(this.props, ["CurrentCollection"]));
      this.props.setCurrentStory(get(nextStory, ["story"]));
      this.props.setAudioplaying(this.props.PlayAudio);
      this.setState({
        currentTime: 0
      });

      // this.setState({ currentStoryId: get(nextStory, ["story"]) });
    } else {
      console.log("audio- not available", currentStoryId);
    }
  };

  handlePrev = () => {
    if (this.hls) {
      this.hls.destroy();
    }
    const stories = get(this.props, ["CurrentCollection", "items"] || []);
    const currentStoryId = get(this.props, ["CurrentStory", "id"]);
    // const magazineID = get(this.props, ["data", "issueCollection", "id"]);

    if (stories.length === 0) return; // No stories available

    const currentStoryIndex = stories.findIndex(story => story.id === currentStoryId);
    if (currentStoryIndex === -1) return; // No valid current story

    const prevIndex = (currentStoryIndex - 1 + stories.length) % stories.length;
    const prevStory = stories[prevIndex];

    // Check if previous story has audio available
    const audioAvailable = get(prevStory, ["story", "metadata", "story-attributes", "audiolength"], 0);
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    if ((storyType && storyType !== undefined && storyType.includes("Audiobook")) || audioAvailable > 0) {
      const pauseEventName =
        storyType && storyType.includes("Audiobook") ? "audiobookprevious" : "audiomagazineprevious";

      //  console.log("Audio available for the previous story");
      //  this.initializePlayer(magazineID, get(prevStory, ["story", "id"]));
      // this.handlePlay(get(this.props, ["data", "issueCollection"]), get(prevStory, ["story"]));
      this.props.setCurrentStory(get(prevStory, ["story"]));
      this.props.setAudioplaying(this.props.PlayAudio);
      this.setState({
        currentTime: 0
      });

      WebEngage.Webplayer(get(prevStory, ["story"]), pauseEventName, get(this.props, ["CurrentCollection"]));
      DeepBI.PingAudioplayer(get(prevStory, ["story"]), pauseEventName, get(this.props, ["CurrentCollection"]));
    } else {
      console.log("Audio not available for the previous story", prevStory.id);
      // Optionally, you can call handlePrev recursively to skip stories without audio
      // this.handlePrev();
    }
  };

  closeMiniPlayer = () => {
    const storyType = get(this.props, ["CurrentStory", "metadata", "story-attributes", "contenttype"]);

    const pauseEventName =
      storyType && storyType.includes("Audiobook") ? "audiobookminiplayerclosed" : "audiomagazineminiplayerclosed";

    WebEngage.Webplayer(get(this.props, ["CurrentStory"]), pauseEventName, get(this.props, ["CurrentCollection"]));
    DeepBI.PingAudioplayer(
      get(this.props, ["CurrentStory"]),
      pauseEventName,
      get(this.props, ["CurrentCollection"]),
      this.formatSecondsTime(this.audioRef.current.currentTime)
    );
    if (this.audioRef.current) {
      this.audioRef.current.pause();
      this.audioRef.current.currentTime = 0;
      if (this.hls) {
        this.hls.destroy();
        this.hls = null;
      }
      this.audioRef.current.src = ""; // Reset the src to ensure proper reinitialization
      this.props.setMiniPlayerVisibility(false);
      this.props.setAudioplaying(false);
      this.props.setClosePlayer(true);
      this.props.setCurrentStory(null);
      this.setState({ selectedSpeed: 1, playing: false });
    }
  };

  handleMobileLoginClose = () => {
    this.setState({ showLoginModal: false });
  };

  render() {
    const { playing, currentTime, duration, selectedSpeed } = this.state;
    const { enablePlayer } = this.props;

    const storyHeadline = get(this.props, ["CurrentStory", "headline"], () => null);
    const storyImage = get(this.props, ["CurrentStory", "hero-image-s3-key"], () => null);
    const AudioStoryId = get(this.props, ["CurrentStory", "id"]);

    if (!enablePlayer) {
      return <div className="no audio found" />;
    }

    return (
      <div className={styles["main-container"]}>
        {this.state.showLoginModal && <MobileLogin onClose={this.handleMobileLoginClose} />}

        <div className={styles["Ad-container-outer"]} id="ad-message">
          <div className={styles["ad-container"]}>
            {/* <div className={styles["skip-ad"]}>Skip Ad</div> */}
            <div className={styles["ad-controls"]}>
              <div>Advertisement</div>
              <div
                className={styles["remove-ad"]}
                onClick={() =>
                  window.open(
                    "https://www.vikatan.com/store?utm_source=direct_web&utm_medium=web&utm_campaign=audio_ad",
                    "_blank"
                  )
                }
              >
                Remove Ad
              </div>
            </div>
            <div id="controls" className={styles["ad-timer"]}>
              <div id="ad-time-left" className={styles["ad-timer-left"]} />
              {/* <div id="ad-progress-bar" style={{ width: "0%", height: "2px", backgroundColor: "yellow" }} /> */}
            </div>
            <div id="controls" className={styles["ad-progresbar"]}>
              {/* <div id="ad-time-left" className={styles["ad-timer-left"]} /> */}
              <div id="ad-progress-bar" style={{ width: "0%", height: "2px", backgroundColor: "yellow" }} />
            </div>
            <div ref={this.adContainerRef} style={{ width: 300, height: 250 }} />
            <div className={styles["banner-ad"]}>
              <CompanionAd />
            </div>
          </div>
        </div>
        <div className={styles["new-audio-player-wrapper"]} id="play-button">
          <div className={styles["mini-player-toggle"]} onClick={this.closeMiniPlayer}>
            <Icon type="close" className={styles["mini-player-close-icon"]} />
          </div>
          <div className={styles["audio-player-wrapper"]}>
            <div>
              <div className={styles["mini-player-controls"]}>
                <div>
                  <div className={`${styles["audio-controls"]} hidden-mobile`}>
                    <audio
                      id={`audio-${AudioStoryId}`}
                      ref={this.audioRef}
                      onTimeUpdate={this.handleTimeUpdate}
                      onEnded={this.setFinished}
                      preload="none"
                    />

                    <div className={styles["audio-header"]}>
                      {storyImage && (
                        <ResponsiveImageWithFallback
                          className={styles["image-wrapper"]}
                          slug={storyImage}
                          metadata={{}}
                          alt={storyHeadline}
                          imgParams={{ auto: ["format", "compress"] }}
                          sources={generateImageSources(
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
                          )}
                        />
                      )}
                    </div>
                    <div className={styles["audio-marquee"]}>
                      <marquee>
                        {" "}
                        {storyHeadline && <h2 className={styles["audio-headline"]}>{storyHeadline}</h2>}{" "}
                      </marquee>

                      <div className={`${styles["audio-controls-grid"]}`}>
                        <div className={styles["audio-controls-icons"]}>
                          <div className={`${styles["audio-control-icons"]}`} onClick={this.handlePrev}>
                            <Icon type="icon-audio-previous" className={`${styles["audio-icon"]}`} />
                          </div>
                          <div
                            onClick={() => this.skip("back")}
                            className={`${styles["fast-backward-outer"]} ${styles["audio-control-icons"]}`}
                          >
                            <Icon type="backward" className={`${styles["fast-backward"]} ${styles["audio-icon"]}`} />
                          </div>
                          <div
                            onClick={() => this.skip("fwd")}
                            className={`${styles["fast-forward-outer"]} ${styles["audio-control-icons"]}`}
                          >
                            <Icon type="forward" className={`${styles["fast-forward"]} ${styles["audio-icon"]}`} />
                          </div>
                          <div className={`${styles["audio-control-icons"]}`} onClick={this.handleNext}>
                            <Icon type="icon-audio-next" className={`${styles["audio-icon"]}`} />
                          </div>
                          <div
                            className={`${styles["audio-control-icons"]}`}
                            onClick={playing ? this.handlePause : this.handlePlay}
                          >
                            {playing ? (
                              <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                            ) : (
                              <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                            )}
                          </div>
                        </div>
                        <div className={styles["audio-controls-top-section"]}>
                          <div className={styles["progress-bar"]}>
                            <input
                              type="range"
                              min={0}
                              max={duration}
                              step={0.01}
                              value={currentTime}
                              onChange={this.handleSeek}
                              className={styles["progress"]}
                            />
                          </div>
                          <div className={styles["audio-in-out-time"]}>
                            <div className={styles["audio-text"]}>{this.formatTime(currentTime)}</div>
                            <div className={styles["audio-text"]}>{this.formatTime(duration)}</div>
                          </div>
                        </div>
                        <div className={styles["audio-controls-bottom-section"]}>
                          <div className={styles["audio-controls-speed"]}>
                            <ul className={styles["speed-list"]}>
                              {[1, 1.2, 1.5].map(
                                speed =>
                                  selectedSpeed === speed && (
                                    <li key={speed} onClick={() => this.handleSpeedChange(speed)}>
                                      {speed}x
                                    </li>
                                  )
                              )}
                            </ul>
                            <div className="hidden-mobile">Speed</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={`${styles["audio-controls"]} hidden-desktop`}>
                    <audio
                      id={`audio-${AudioStoryId}`}
                      ref={this.audioRef}
                      onTimeUpdate={this.handleTimeUpdate}
                      onEnded={this.setFinished}
                      preload="none"
                    />

                    <div className={styles["audio-header"]}>
                      {storyImage && (
                        // <figure className={styles["image-wrapper"]}>
                        //   <ResponsiveImage
                        //     eager
                        //     slug={storyImage} // Assuming storyImage is a slug for ResponsiveImage
                        //     metadata={{}} // Provide appropriate metadata if available
                        //     alt={storyHeadline}
                        //     aspectRatio={[16, 9]}
                        //     defaultWidth={320}
                        //     widths={[320, 500, 700]}
                        //     sizes="(max-width: 1024px) 100vw, 70vw"
                        //     imgParams={{ auto: ["format", "compress"] }}
                        //   />
                        // </figure>
                        <ResponsiveImageWithFallback
                          className={styles["image-wrapper"]}
                          slug={storyImage}
                          metadata={{}}
                          alt={storyHeadline}
                          imgParams={{ auto: ["format", "compress"] }}
                          sources={generateImageSources(
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
                            { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
                          )}
                        />
                      )}
                    </div>
                    <div className={styles["audio-marquee"]}>
                      <marquee>
                        {" "}
                        {storyHeadline && <h2 className={styles["audio-headline"]}>{storyHeadline}</h2>}{" "}
                      </marquee>
                      <div className={styles["audio-controls-top-section"]}>
                        <div className={styles["progress-bar"]}>
                          <input
                            type="range"
                            min={0}
                            max={duration}
                            step={0.01}
                            value={currentTime}
                            onChange={this.handleSeek}
                            className={styles["progress"]}
                          />
                        </div>
                        <div className={styles["audio-in-out-time"]}>
                          <div className={styles["audio-text"]}>{this.formatTime(currentTime)}</div>
                          <div className={styles["audio-text"]}>{this.formatTime(duration)}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles["audio-controls-grid"]}`} id="play-button">
                      <div className={styles["audio-controls-icons"]}>
                        <div className={`${styles["audio-control-icons"]}`} onClick={this.handlePrev}>
                          <Icon type="icon-audio-previous" className={`${styles["audio-icon"]}`} />
                        </div>
                        <div
                          onClick={() => this.skip("back")}
                          className={`${styles["fast-backward-outer"]} ${styles["audio-control-icons"]}`}
                        >
                          <Icon type="backward" className={`${styles["fast-backward"]} ${styles["audio-icon"]}`} />
                        </div>
                        <div
                          className={`${styles["audio-control-icons"]}`}
                          onClick={playing ? this.handlePause : this.handlePlay}
                        >
                          {playing ? (
                            <Icon type="pause" className={`${styles["pause"]} ${styles["audio-icon"]}`} />
                          ) : (
                            <Icon type="play" className={`${styles["play"]} ${styles["audio-icon"]}`} />
                          )}
                        </div>
                        <div
                          onClick={() => this.skip("fwd")}
                          className={`${styles["fast-forward-outer"]} ${styles["audio-control-icons"]}`}
                        >
                          <Icon type="forward" className={`${styles["fast-forward"]} ${styles["audio-icon"]}`} />
                        </div>
                        <div className={`${styles["audio-control-icons"]}`} onClick={this.handleNext}>
                          <Icon type="icon-audio-next" className={`${styles["audio-icon"]}`} />
                        </div>
                        <div className={styles["audio-controls-speed"]}>
                          <ul className={styles["speed-list"]}>
                            <ul className={styles["speed-list"]}>
                              {[1, 1.2, 1.5].map(
                                speed =>
                                  selectedSpeed === speed && (
                                    <li key={speed} onClick={() => this.handleSpeedChange(speed)}>
                                      {speed}x
                                    </li>
                                  )
                              )}
                            </ul>
                          </ul>
                          <div className="hidden-mobile">Speed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AudioMagazinePlayer.propTypes = {
  story: PropTypes.object.isRequired,
  storyImage: PropTypes.string,
  storyHeadline: PropTypes.string,
  id: PropTypes.string,
  playState: PropTypes.bool.isRequired,
  setMiniPlayerVisibility: PropTypes.bool.isRequired,
  enablePlayer: PropTypes.bool.isRequired,
  PlayAudio: PropTypes.bool.isRequired,
  setAudioplaying: PropTypes.bool.isRequired,
  setCurrentStory: PropTypes.object,
  ClosePlayer: PropTypes.bool.isRequired,
  setClosePlayer: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    data: get(state, ["qt", "data"]),
    enablePlayer: get(state, ["ShowPlayer"]),
    PlayAudio: get(state, ["PlayAudio"]),
    story: get(state, ["qt", "data", "story"], () => null),
    CurrentStory: get(state, ["CurrentStory"]),
    ClosePlayer: get(state, ["ClosePlayer"]),
    CurrentCollection: get(state, ["CurrentCollection"])
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMiniPlayerVisibility: function(isVisible) {
      dispatch({
        type: "SET_MINI_PLAYER_VISIBILITY",
        payload: isVisible
      });
    },
    setAudioplaying: isVisible => {
      dispatch({
        type: "AUDIO_PLAYING",
        payload: isVisible
      });
    },
    setCurrentStory: story => {
      dispatch({
        type: "CURRENT_STORY",
        payload: story
      });
    },
    setClosePlayer: isVisible => {
      dispatch({
        type: "CLOSE_PLAYER",
        payload: isVisible
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AudioMagazinePlayer);
